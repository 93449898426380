import React from 'react'
import Header from '../../Header/Header'

const MyCertificate = () => {
  return (
    <div>
    <Header/>
    </div>
  )
}

export default MyCertificate