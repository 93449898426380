import React from 'react'
import Header from '../Header/Header'
import Home from './Home/Home'

const HomeF = () => {
  return (
    <div>
    <Header/>
    <Home/>
    </div>
  )
}

export default HomeF